
input::-webkit-input-placeholder {
  color: $light-5;
}
input::-moz-placeholder {
  color: $light-5;
}
input:-moz-placeholder {
  color: $light-5;
}
input:-ms-input-placeholder {
  color: $light-5;
}
textarea::-webkit-input-placeholder {
  color: $light-5;
}
textarea::-moz-placeholder {
  color: $light-5;
}
textarea:-moz-placeholder {
  color: $light-5;
}
textarea:-ms-input-placeholder {
  color: $light-5;
}
input:focus::-webkit-input-placeholder{color:transparent;transition:all .5s ease}input:focus::-moz-placeholder{color:transparent;transition:all .5s ease}input:focus:-moz-placeholder{color:transparent;transition:all .5s ease}input:focus:-ms-input-placeholder{color:transparent;transition:all .5s ease}textarea:focus::-webkit-input-placeholder{color:transparent;-webkit-transition:all .5s ease;-o-transition:all .5s ease;-moz-transition:all .5s ease;transition:all .5s ease}textarea:focus::-moz-placeholder{color:transparent;-webkit-transition:all .5s ease;-o-transition:all .5s ease;-moz-transition:all .5s ease;transition:all .5s ease}textarea:focus:-moz-placeholder{color:transparent;-webkit-transition:all .5s ease;-o-transition:all .5s ease;-moz-transition:all .5s ease;transition:all .5s ease}textarea:focus:-ms-input-placeholder{color:transparent;-webkit-transition:all .5s ease;-o-transition:all .5s ease;-moz-transition:all .5s ease;transition:all .5s ease}



//SEARCH

.search {
  &-field {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 8px rgba(199, 202, 215, 0.6);
    border-radius: 40px;
    padding: 10px 50px 10px 23px;
    color: $black;
    width: 100%;
    display: block;
    font-size: 16px;
  }
  &-submit {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: url(../img/icons/search.svg) no-repeat center #F4A863;
    &:hover {
      box-shadow: 0px 6px 8px rgba($orange, 0.6);
    }
  }

  @media (max-width:$media-2) {
    display: block;
    position: fixed;
    width: auto;
    max-width: 100%;
    top: 20px;
    left: 20px;
    right: 20px;
    z-index: 9999;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    border-radius: 40px;
    -webkit-overflow-scrolling: touch;
    transform: translateY(100px);
    transition: all 0.5s ease;
    &-field {
      font-size: 14px;
      height: 38px;
      line-height: 38px;
    }
    &.open{
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      box-shadow: 0px 15px 30px 0px rgba(0,0,0,0.95);
    }
  }

}


//btn

.btn {
  font-family: $fontTitle;
  color: $white;
  background-color: $orange;
  box-shadow: 0px 4px 4px rgba(239, 127, 26, 0.2);
  border-radius: $radius-1;
  text-align: center;
  padding: 15px 30px;
  &:hover {
    background-color: $blue;
    box-shadow: 0px 4px 4px rgba($blue, 0.1);
  }

  &-gray {
    border: 1px solid #B8CCD8;
    border-radius: 3px;
    font-size: 13px;
    text-align: center;
    color: #A2B4BE;
    padding: 10px 20px 8px 20px;
    &:hover {
      background: $light-1;
      border-color: $light-1;
      color: $blue;
    }
  }

  &-green {
    background: $green;
    box-shadow: 0px 4px 4px rgba($green, 0.2);
    &:hover {
      background: $red;
      box-shadow: 0px 4px 4px rgba($red, 0.2);
    }
  }

}

.form-field {
  font-size: .95em;
  display: block;
  input {
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid #F3F6FA;
    background-color: #F3F6FA;
    color: $black;
    padding: 12px 20px;
    &:focus {
      border-color: $blue;
    }
  }
  input, .nice-select {
    margin: 8px 0 20px 0;
    display: block;
    width: 100%;
  }
}

.consent {
  font-size: 12px;
  label {
    display: block;
    padding-left: 28px;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      cursor: pointer;
      z-index: 1;
    }
  }
  &-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid $gray;
    transition: .5s;
  }
  input:checked + .consent-icon {
    background: $blue;
    border-color: $blue;
  }
  input:hover + .consent-icon {
    border-color: $blue;
  }
}

