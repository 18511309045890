
.advantages {
  display: flex;
  flex-wrap: wrap;
  &-item {
    text-align: center;
    width: 25%;
    padding: 0 15px;
    &__icon {
      width: 85px;
      height: 85px;
      padding: 10px;
      margin: 0 auto 25px auto;
      @include verticalCenter;
      img {
        max-height: 100%;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: .5s;
        transform: rotate(45deg);
        border-radius: 8px;
      }
    }
    &:nth-child(odd) {
      .advantages-item__icon {
        &:before {
          background-color: $green;
        }
      }
    }
    &:nth-child(even) {
      .advantages-item__icon {
        &:before {
          background-color: $orange;
        }
      }
    }
    &:hover {
      .advantages-item__icon {
        &:before {
          transform: rotate(135deg);
        }
      }
    }
    h4 {
      margin-bottom: 5px;
    }
    @media (max-width: $media-2) {
      width: 50%;
      margin-bottom: 35px;
    }
    @media (max-width: $media-3) {
      width: 50%;
      padding: 0 2px;
      margin-bottom: 20px;
      &__icon {
        width: 36px;
        height: 36px;
        padding: 5px;
        margin-bottom: 10px;
      }
      h4 {
        font-size: 1em;
      }
      p {
        font-size: .8em;
      }
    }
  }
}
