
.jumbotron {
  padding: 70px 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: $radius-1;
  &-content {
    width: 500px;
    max-width: 100%;
    z-index: 1;
    p {
      font-size: 1.2em;
      margin-bottom: 30px;
      font-weight: 600;
    }
    .btn {
      font-size: 1.25em;
      min-width: 245px;
    }
  }
  @media (max-width: $media-2) {
    padding: 35px 20px;
    color: $white;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      opacity: .5;
      border-radius: $radius-1;
    }
    h1 {
      color: $white;
    }
  }
  @media (max-width: $media-3) {
    text-align: center;
    &-content {
      width: 100%;
    }
    h1 {
      text-align: center;
    }
    p {
      font-size: 1em;
    }
    .btn {
      min-width: auto;
      font-size: 1.1em;
    }
  }
}
