
.modal, .modal-content > * {
  transform: translateY(150px);
  opacity: 0;
  -webkit-transition: opacity .5s, -webkit-transform .5s;
  transition: opacity .5s, -webkit-transform .5s;
  -o-transition: opacity .5s, -o-transform .5s;
  -moz-transition: transform .5s, opacity .5s, -moz-transform .5s;
  transition: transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s, -webkit-transform .5s, -moz-transform .5s, -o-transform .5s;
}

.fancybox-slide--current .modal, .fancybox-slide--current .modal-content > * {
  opacity: 1;
  transform: translateY(0);
}

.modal{
  display: none;
  max-width: 100%;
  border-radius: $radius-1;
  background-color: $white;
  overflow: visible;
  padding: 30px 20px;
  width: 400px;
  h3 {
    margin-bottom: 20px;
  }
  &-movement {
    margin-bottom: 25px;
    color: $light-4;
    font-size: .9em;
  }
  .consent {
    margin: 25px auto 0 auto;
  }
  .btn {
    min-width: 200px;
    width: auto;
    display: inline-block;
  }
}

.forgot-password {
  text-align: center;
  margin-top: 25px;
  font-size: 12px;
}

.fancybox-bg {
  background: rgba(53, 59, 79, 1);
}

#success {
  text-align: center;
  h4 {
    text-align: center;
  }
}