
.catalog {
  margin: 0 -10px 30px -10px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $media-3) {
    margin: 0 -3px 30px -3px;
  }
  &-item {
    width: calc(25% - 20px);
    text-align: center;
    padding: 15px 10px;
    margin: 0 10px 15px 10px;
    border-radius: $radius-1;
    overflow: hidden;
    font-weight: 700;
    color: $blue;
    &__img {
      width: 100%;
      height: 110px;
      margin-bottom: 10px;
      @include verticalCenter;
      img {
        max-height: 100%;
      }
    }
    &:hover {
      box-shadow: 0px 4px 16px rgba(173, 201, 215, 0.25);
    }
    @media (max-width: $media-1) {
      width: calc(33.3% - 20px);
    }
    @media (max-width: $media-3) {
      width: calc(50% - 5px);
      margin: 0 2px 10px 2px;
      font-size: .9em;
      padding: 10px 5px;
      &__img {
        height: 80px;
        margin-bottom: 10px;
      }
    }
  }
}
