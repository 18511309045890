
.product {
  display: flex;
  &-slider {
    width: 43%;
    margin-bottom: 30px;
    .swiper-slide {
      overflow: hidden;
      &:before {
        content: '';
        padding-top: 100%;
        display: block;
      }
      img {
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .gallery-top {
      border: 1px solid $light-2;
      border-radius: $radius-1;
      margin-bottom: 10px;
    }
    .gallery-thumbs {
      .swiper-slide {
        cursor: pointer;
        border: 1px solid $light-2;
        border-radius: $radius-1;
      }
    }
  }
  &-text {
    width: 57%;
    padding-left: 50px;
    h2 {
      font-size: 30px;
      margin-bottom: 10px;
    }
    .vendor-code {
      color: $light-4;
      margin: 0 0 10px 0;
    }
    .availability {
      margin-top: 20px;
      margin-bottom: 3px;
      padding-bottom: 0;
    }
    &__list {
     padding-bottom: 15px;
      font-size: .9em;
      p {
        margin: 5px 0;
      }
    }
    &__info {
      padding: 25px 30px;
      background: #F7FAFC;
      border-radius: $radius-1;
      margin-bottom: 15px;
      @media (max-width: $media-3) {
        padding: 20px 15px;
      }
    }
  }

  &-hidden {
    display: none;
    margin-bottom: 30px;
    h2 {
      margin-bottom: 5px;
    }
    @media (max-width: $media-1) {
      display: block;
    }
  }

  @media (max-width: $media-1) {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    &-text {
      width: 100%;
      padding-left: 0;
      h2, .vendor-code {
        display: none;
      }
    }
    &-slider {
      min-width: 280px;
    }
  }
}

.certificate {
  margin-right: -15px;
  a {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 200px;
    max-width: calc(50% - 30px);
    img {
      width: 100%;
    }
  }
}


