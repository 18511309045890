
.basket-head {
  margin-bottom: 30px;
  @include horizonCenterBetween;
  @media (max-width:$media-3) {
    flex-direction: column;
    justify-content: center;
  }
  h2 {
    margin-right: 30px;
    margin-bottom: 10px;
  }
  .link {
    font-weight: 700;
    font-size: 1.1em;
  }
  &__col {
    @include horizonCenterBetween;
    @media (max-width:$media-3) {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      h2 {
        margin-right: 0;
      }
    }
  }
}

.content-basket {
  float: left;
  width: calc(100% - 290px - 30px);
  margin-bottom: 35px;
  @media (max-width:$media-1) {
    width: 100%;
  }
}

.checkout {
  margin-top: 80px;
  margin-bottom: 35px;
  float: right;
  width: 290px;
  background: $light-1;
  border-radius: $radius-1;
  padding: 25px 30px 20px 30px;
  @media (max-width:$media-1) {
    width: 100%;
    margin-top: 0;
    text-align: center;
  }
  .btn {
    width: 100%;
    & + .btn {
      margin-top: 10px;
    }
  }
  &-form {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #DFE8EE;
    p {
      font-size: .9em;
    }
  }
  textarea{
    background: #FFFFFF;
    box-shadow: inset 0px 2px 3px rgba(230, 237, 243, 0.6);
    border-radius: 3px;
    height: 110px;
    margin: 10px 0 15px 0;
    width: 100%;
    display: block;
    padding: 15px;
    font-size: .95em;
  }
}

.note {
  font-size: .8em;
  text-align: center;
  margin-top: 15px;
}

.amount {

}
.discount-percentage {
  color: $green;
  font-weight: 700;
  margin: 8px 0;
  font-size: 1.1em;
}
.amount-total {
  font-size: 20px;
  font-weight: 700;
}


.basket-table {
  table {
    width: 100%;
  }
  thead {
    border-bottom: 2px solid $green;
    th {
      text-align: left;
      font-weight: normal;
      padding: 10px 15px 10px 0;
      &:last-child {
        padding-right: 0;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid $light-2;
      &:last-child {
        border-bottom: transparent;
      }
    }
    td {
      padding: 30px 15px 30px 0;
      &:last-child {
        padding-right: 0;
      }
    }
  }
  .product-preview {
    @include horizonCenterBetween;
    &__img {
      width: 130px;
      height: 130px;
      margin-right: 20px;
      @media (max-width:$media-2) {
        width: 100px;
        height: 100px;
      }
    }
    &__title {
      flex: 1;
    }
  }
  .price {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 18px;
  }
  .price-old {
    margin-top: 0;
    padding-top: 0;
  }
}

.delete {
  width: 20px;
  height: 20px;
  background-image: url(../img/icons/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  &:hover {
    background-image: url(../img/icons/close-red.svg);
  }
}


@media (max-width:$media-3) {

  .basket-table {
    table {
      display: block;
    }
    thead {
      display: none;
    }
    tbody {
      display: block;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px 0;
      td {
        padding-top: 5px;
        padding-bottom: 5px;
        position: static;
        &:nth-child(1) {
          width: 100%;
        }
        .product-preview {
          justify-content: center;
          &__img {
            width: 80px;
            height: 80px;
            margin-right: 15px;
          }
          &__title {
            padding-right: 20px;
          }
        }
      }
    }
    .delete {
      position: absolute;
      top: 18px;
      right: 0;
      z-index: 2;
    }
  }

}