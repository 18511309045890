
.slider-catalog {
  .swiper-slide {
    text-align: center;
    width: 20%;
    padding: 0 5px;
    @media (max-width: $media-1) {
      width: 25%;
    }
    @media (max-width: $media-2) {
      width: 33.3%;
    }
    @media (max-width: $media-3) {
      width: 50%;
    }
    @media (max-width: 580px) {
      width: 100%;
    }

  }
  .product-preview {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    p {
      font-size: .9em;
    }
    &__img {
      width: 210px;
      max-width: 85%;
      margin-bottom: 15px;
    }
    &__price {
      margin-bottom: 5px;
      .price {
        font-size: 21px;
      }
    }
    .btn {
      margin-top: 10px;
    }
    &:hover {
      .product-preview__title {
        color: $black;
      }
    }
  }
  .swiper-button {
    top: 147px;
  }

}

.product-preview {

  &__img {
    height: 210px;
    border: 1px solid #EAEAEA;
    border-radius:$radius-1;
    @include verticalCenter;
    img {
      max-height: 100%;
      border-radius:$radius-1;
    }
  }
  &__title {
    font-weight: 700;
    color: $blue;
    transition: .5s;
    span {
      display: block;
      font-size: .9em;
      color: $black;
    }
  }
  .vendor-code {
    margin-top: 5px;
  }
  &__calc {
    width: 410px;
    max-width: 100%;
    margin-top: 15px;
    @include horizonCenterBetween;
    @media (max-width: $media-1) {
      width: auto;
      flex-direction: column;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: flex-start;
    }
    & > * {
      margin-right: 15px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.vendor-code {
  display: block;
  font-size: .9em;
  color: $light-4;
  margin: 4px 0 8px 0;
  font-weight: 500;
}

.product-mark {
  position: absolute;
  color: $white;
  z-index: 2;
  top: -35px;
  left: 0;
  padding-top: 3px;
  font-size: 13px;
  font-weight: 700;
  pointer-events: none;
  width: 68px;
  height: 68px;
  @include verticalCenter;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../img/icons/mark.png) no-repeat center;
    top: 0;
    left: 0;
    z-index: -1;
  }

}

.products {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  .product-preview {
    width: 100%;
    margin-bottom: 35px;
    @include horizonCenterBetween;
    & > * {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
    &__img {
      width: 210px;
      max-width: 100%;
    }
    &__info {
      flex: 1;
    }
    &__title {
      color: $blue;
      &:hover {
        color: $orange;
      }
    }
    .vendor-code {
      color: $light-4;
      margin-bottom: 15px;
    }
    p {
      font-size: .9em;
    }

    &__price {
      .price {
        margin-right: 10px;
      }
    }
    &__availability {
      text-align: right;
    }
    .btn {
      white-space: nowrap;
      font-size: 1.15em;
    }
    @media (max-width: $media-3) {
      width: 50%;
      padding: 0 10px;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      * {
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      & > * {
        margin-right: 0;
        margin-bottom: 10px;
      }
      &__info {
        width: 100%;
        p {
          font-size: 12px;
        }
      }
      .price {
        font-size: 20px;
      }
      &__title {
        font-size: 1em;
      }
      .vendor-code {
        font-size: .8em;
        margin: 3px 0 5px 0;
      }
      &__availability {
        .btn {
          font-size: 1em;
        }
        .availability {
          text-align: center;
          flex-direction: column;
          margin-bottom: 10px;
          p {
            margin-right: 0;
            margin-bottom: 5px;
          }
          &-stat {
            justify-content: center;
          }
        }
      }
      &__img {
        order: 0;
        width: 140px;
        height: 120px;
      }
    }
    @media (max-width: 413px) {
      width: 100%;
    }
  }
}

.price {
  font-weight: bold;
  font-size: 26px;
  white-space: nowrap;
  &-old {
    display: inline-block;
    vertical-align: middle;
    margin: 3px 0;
    text-decoration: line-through;
    color: $light-5;
    font-size: 14px;
    white-space: nowrap;
  }
}

.number {
  width: 123px;
  height: 34px;
  input {
    width: 100%;
    display: block;
    text-align: center;
    height: 34px;
    line-height: 34px;
    font-weight: bold;
    font-size: .95em;
    color: $black;
    padding: 0 34px;
    background: $light-1;
    border-radius: 30px;
  }
  span {
    position: absolute;
    top: 2px;
    bottom: 2px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background-color: $light-2;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    cursor: pointer;
    &.minus {
      left: 2px;
      background-image: url(../img/icons/minus.svg);
      &:hover {
        background-image: url(../img/icons/minus-white.svg);
      }
    }
    &.plus {
      right: 2px;
      background-image: url(../img/icons/plus.svg);
      &:hover {
        background-image: url(../img/icons/plus-white.svg);
      }
    }
  }
}

.availability {
  @include horizonCenter;
  margin-bottom: 20px;
  p {
    font-size: .95em;
    margin-right: 10px;
  }
  &-stat {
    display: flex;
    span {
      background: #dee7ee;
      width: 3px;
      height: 18px;
      margin-left: 2px;
    }
    &--green {
      .active {
        background-color: $green;
      }
    }
    &--orange {
      .active {
        background-color: $orange;
      }
    }
    &--red {
      .active {
        background-color: $red;
      }
    }
  }
}


.pagination {
  padding: 10px 0 20px 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.1em;
  @media (max-width: 413px) {
    font-size: .85em;
  }
  &-count {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    & > * {
      margin: 0 10px;
    }
  }
  a {
    color: $blue;
    &:hover {
      color: $green;
    }
  }
  .swiper-button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $light-2;
    background-size: 14px;
    &.prev {
      transform: scaleX(-1);
    }
    @media (max-width: 413px) {
      display: none;
    }
  }
}
