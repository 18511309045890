
.sidebar {
  width: 310px;
  margin-bottom: 20px;
  background: #F1F6F9;
  border-radius: $radius-1;
  float: left;
  @media (max-width: $media-1) {
    width: 280px;
  }
  @media (max-width: $media-2) {
    width: 100%;
  }
  &-open {
    display: none;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    padding: 12px;
    font-family: $fontTitle;
    @media (max-width: $media-2) {
      display: block;
    }
  }
}

.category-list {
  padding: 15px 10px 15px 30px;
  @media (max-width: $media-2) {
   display: none;
  }
  ul {
    li {
      margin: 10px 0;
      a {
        color: $gray;
        padding-left: 15px;
        font-size: .9em;
        font-weight: 500;
        &:before {
          content: '';
          position: absolute;
          top: 7px;
          left: 0;
          border-radius: 4px;
          width: 4px;
          height: 4px;
          background: $green;
        }
        &:hover {
          color: $green;
        }
      }
    }
  }
}
