
.header {
  background: #353B4F;
  padding: 30px 0 0 0;
  color: $white;
  margin-bottom: 50px;
  z-index: 3;
  @media (max-width:$media-2) {
    padding: 15px 0;
    margin-bottom: 30px;
  }
  &-item {
    .container {
      @include horizonCenterBetween;
      @media (max-width: $media-3) {
        padding-right: 5px;
        padding-left: 5px;
      }
      & > * {
        margin-right: 70px;
        @media (max-width: $media-1) {
          margin-right: 30px;
        }
        @media (max-width: $media-2) {
          margin-right: 15px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-btn {
    @include horizonCenter;
    & > * {
      margin-right: 30px;
      @media (max-width: $media-2) {
        margin-right: 15px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .tel {
      display: none;
        @media (max-width: $media-2) {
          display: inline-block;
          font-size: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: url(../img/icons/phone.svg) no-repeat center $white;
          background-size: 18px;
          margin: 0 5px;
        }
    }
  }
  &-menu {

  }
  .logo {
    img {
      width: 340px;
      @media (max-width: $media-1) {
        width: 250px;
      }
    }
    @media (max-width: $media-2) {
      margin-left: 70px;
      img {
        width: 200px;
      }
    }
    @media (max-width: $media-3) {
      margin-left: 35px;
      margin-right: 0;
      img {
        width: 130px;
      }
    }
  }
  &-column {
    width: 400px;
    max-width: 100%;
    @media (max-width: $media-1) {
      width: 350px;
      .tel {
        font-size: 20px;
      }
    }
    @media (max-width:$media-2) {
      width: auto;
    }
  }
  &-phone {
    padding-bottom: 10px;
    @include horizonCenterBetween;
      & > * {
        margin-right: 15px;
        margin-bottom: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    @media (max-width: $media-2) {
      display: none;
    }
  }
  .call-back {
    color: $blue;
  }
  a, button {
    color: $white;
  }
  .social {
    margin-top: 20px;
  }
}

.user {
  font-size: 1.05em;
  font-weight: 700;
  padding-left: 32px;
  white-space: nowrap;
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    margin-top: -10px;
    position: absolute;
    left: 0;
    background: url(../img/icons/login.svg) no-repeat center;
  }
  &:hover {
    color: $blue;
  }
  @media (max-width: $media-2) {
    margin: 0 3px;
    font-size: 0;
    padding-left: 0;
    height: 30px;
    width: 30px;
    background-color: $white;
    border-radius: 50%;
    &:before {
      width: 30px;
      height: 30px;
      margin-top: -15px;
      background-size: 17px;
    }
  }
}

.basket {
  font-size: 1.05em;
  font-weight: 700;
  white-space: nowrap;
  padding-left: 42px;
  span {
    color: #9CA6C7;
    font-weight: 500;
  }
  &:before {
    content: '';
    width: 28px;
    height: 28px;
    top: 50%;
    margin-top: -14px;
    position: absolute;
    left: 0;
    background: url(../img/icons/basket.svg) no-repeat center;
  }
  &:hover {
    color: $blue;
  }
  @media (max-width: $media-2) {
    margin: 0 3px;
    font-size: 0;
    padding-left: 0;
    width: 30px;
    height: 30px;
    background-color: $white;
    border-radius: 50%;
    &:before {
      width: 30px;
      height: 30px;
      margin-top: -15px;
      background-size: 20px;
    }
  }
}

.tel {
  white-space: nowrap;
  font-weight: bold;
  font-size: 24px;
  &:hover {
    opacity: .7;
  }
}

.call-back {
  color: $blue;
  font-weight: 500;
  font-size: 1.1em;
  white-space: nowrap;
  border-bottom: 1px dashed $blue;
  &:hover {
    border-color: transparent;
  }
}

.open-nav,
.close-nav,
.overlay,
.search-open
{
  display: none;
}

@media (max-width: $media-2) {
  .open-nav {
    display: block;
    background: url(../img/icons/catalog.svg) no-repeat center;
    width: 30px;
    height: 30px;
    background-size: 24px;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -16px;
  }
  .close-nav {
    display: block;
    position: absolute;
    z-index: 1;
    top: 27px;
    right: 15px;
    background: url(../img/icons/close.svg) no-repeat center;
    width: 32px;
    height: 32px;
    background-size: 14px;
  }
  .overlay {
    &.open {
      display: block;
      position: fixed;
      z-index: 9998;
      background: rgba(53, 59, 79, 0.7);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .search-open {
    display: block;
    background: url(../img/icons/search-blue.svg) no-repeat center $white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: 14px;
    margin: 0 3px;
  }
}

@media (max-width: $media-3) {
  .open-nav {
    background-size: 20px;
    left: 5px;
  }
}

.wrap-nav {
  transform: translateY(50%);
  &__title {
    display: none;
  }
  &__hidden {
    display: none;
  }
  @media (max-width:$media-2) {
    display: block;
    position: fixed;
    width: 360px;
    max-width: 85%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    padding: 35px 20px;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    background: #353B4F;
    color: $white;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    &.open{
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      box-shadow: 0px 15px 30px 0px rgba(0,0,0,0.75);
    }
    &__title {
      display: block;
      color: $white;
      font-size: 1.3em;
      margin-bottom: 35px;
      text-transform: uppercase;
      font-family: $fontTitle;
    }
    &__hidden {
      display: block;
    }
    .tel {
      margin-bottom: 10px;
      display: block;
    }
    .call-back {
      margin: 20px 0;
      display: inline-block;
    }
  }
}

.menu {
  background: $green;
  box-shadow: 0px 6px 8px rgba(16, 141, 44, 0.26);
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  @media (max-width:$media-2) {
    display: block;
    background: none;
    box-shadow: none;
    border-radius: 0;
    overflow: visible;
    border-bottom: 1px solid $light-4;
    padding-bottom: 10px;
  }
  li {
    flex: 1;
    @media (max-width:$media-2) {
      margin: 15px 0;
      display: block;
    }
    &.menu-item--catalog {
      flex: 0;
       a {
         padding-left: 70px;
         padding-right: 30px;
         font-size: 1.1em;
         text-transform: uppercase;
         background: #0E952C;
         &:before {
           content: '';
           position: absolute;
           top: 50%;
           left: 30px;
           width: 20px;
           height: 20px;
           background: url(../img/icons/catalog.svg) no-repeat center;
           margin-top: -12px;
           @media (max-width:$media-2) {
             display: none;
           }
         }
         &:hover {
           background: $blue;
         }
         @media (max-width:$media-2) {
           padding-left: 0;
           padding-right: 0;
           background: transparent;
           &:hover {
             background: transparent;
           }
         }
       }
    }
    &.menu-item--new {
      a {
        font-size: 1.1em;
        text-transform: uppercase;
      }
    }
    a {
      white-space: nowrap;
      color: $white;
      font-size: 1.15em;
      font-family: $fontTitle;
      padding: 15px 30px;
      @include verticalCenter;
      &:hover {
        background-color: $blue;
      }
      @media (max-width: $media-1) {
        padding: 15px;
      }
      @media (max-width: $media-2) {
        display: block;
        padding: 0;
        white-space: normal;
      }
    }
  }
}

