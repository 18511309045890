
.footer {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0.55%, #FFFFFF 100%), #EAF2F7;
  @media (max-width:$media-3) {
    font-size: 14px;
    h5 {
      margin-bottom: 10px;
    }
  }
  &-item {
    padding: 20px 0;
    @include horizonCenterBetween;
    align-items: flex-start;
    @media (max-width:$media-3) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    & > * {
      margin-right: 30px;
      margin-bottom: 15px;
      margin-top: 15px;
      @media (max-width:$media-3) {
        margin-right: 0;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &__col {

    }
  }
  .logo {
    img {
      width: 160px;
      @media (max-width:$media-1) {
        width: 100px;
      }
      @media (max-width:$media-3) {
        width: 80px;
      }
    }
  }
  &-menu {
    ul {
      column-gap: 30px;
      column-count: 2;
      li {
        margin-bottom: 15px;
        a {
          color: $blue;
          font-weight: 600;
          font-size: .95em;
          &:hover {
            color: $black;
          }
        }
      }
    }
    @media (max-width:$media-2) {
      display: none;
    }
  }
  &-contacts {
    width: 260px;
    .tel {
      display: block;
      margin-bottom: 5px;
      color: $black;
      font-size: 20px;
      &:hover {
        color: $blue;
      }
    }
  }
}

.address {
  font-weight: 600;
  font-size: .95em;
  line-height: 1.4;
  margin-bottom: 10px;
}

.email {
  display: block;
  font-weight: 500;
  color: $blue;
  &:hover {
    color: $green;
  }
}

.copyright {
  text-align: center;
  border-top: 1px solid #D9E6EE;
  padding: 15px 0;
  font-weight: 500;
  font-size: .9em;
}

.social {
  display: flex;
  a {
    margin-right: 8px;
    width: 36px;
    text-align: center;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      opacity: .7;
    }
  }
}


//subscription

.subscription {
  padding: 30px 0;
  background: url(../img/subscription-bg.jpg) no-repeat center;
  background-size: cover;
  color: $white;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $media-2) {
      flex-wrap: wrap;
    }
    & > * {
      margin: 10px 0;
    }

  }
  h3 {
    text-align: left;
    width: 510px;
    margin-right: 50px;
    @media (max-width: $media-2) {
      text-align: center;
      width: 100%;
      margin-right: 0;
    }
  }
  &-form {
    width: 400px;
    max-width: 100%;
    &__field {
      width: 100%;
      display: block;
      background: #FFFFFF;
      box-shadow: inset 0px 4px 8px rgba(199, 202, 215, 0.6);
      border-radius: 40px;
      padding: 10px 150px 10px 23px;
      font-size: 14px;
      @media (max-width: $media-3) {
        padding-right: 23px;
        text-align: center;
      }
    }
    &__submit {
      top: 3px;
      right: 3px;
      bottom: 3px;
      @include verticalCenter;
      background: $orange;
      color: $white;
      font-size: 14px;
      border-radius: 40px;
      width: 130px;
      text-align: center;
      position: absolute;
      z-index: 2;
      &:hover {
        box-shadow: 0px 6px 8px rgba($orange, 0.6);
      }
      @media (max-width: $media-3) {
        position: relative;
        display: block;
        margin: 10px auto;
        padding: 15px 10px;
      }
    }
  }
}