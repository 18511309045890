
//normalize

*{position:relative;margin:0;padding:0;box-sizing:border-box}a,button,span{display:inline-block}input,textarea{display:block;width:100%}table{width:100%;border-collapse:collapse}a{background-color:transparent;text-decoration:none}img{border:none;max-width:100%;height:auto}a,button{outline:0;cursor:pointer}li{list-style:none}button{background:0 0;border:none}a,button,input,textarea{color:$black;font-weight:inherit;-webkit-appearance:none;transition:all .5s ease}:after,:before{box-sizing:border-box}textarea{resize:none;outline:0;border:none;-webkit-appearance:none;border-radius:0;background:0 0}input{outline:0;border:none;-webkit-appearance:none;border-radius:0;background:0 0}input[type=number]{-moz-appearance:textfield}input::-webkit-inner-spin-button,input::-webkit-outer-spin-button{-webkit-appearance:none}input[type=submit]{cursor:pointer;-webkit-appearance:none;}input[type=search]::-webkit-search-cancel-button{background:0 0;display:none}.text-align-left{text-align:left}.text-align-center{text-align:center}.text-align-right{text-align:right}.full-img img{width:100%}figure{text-align:center;margin-bottom:30px;margin-top:5px;font-size:.75em;opacity:.8}[hidden]{display:none!important}.img-full{width: 100%;}.clearfix{&:after{content: '';clear: both;display: block}}

//title


h1, .h1 {
  font-size: 40px;
  font-family: $fontTitle;
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.2;
  color: $blue;
  @media (max-width: $media-3) {
    font-size: 28px;
  }
}
h2, .h2 {
  font-size: 32px;
  font-family: $fontTitle;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: $media-3) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
h3, .h3 {
  font-size: 24px;
  font-family: $fontTitle;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: $media-3) {
    font-size: 20px;
  }
}
h4, .h4 {
  font-size: 22px;
  font-family: $fontTitle;
  margin-bottom: 15px;
  @media (max-width: $media-1) {
    font-size: 20px;
  }
  @media (max-width: $media-3) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
h5, .h5 {
  font-size: 1.1em;
  margin-bottom: 15px;
  font-family: $fontTitle;
}
h6, .h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}

//text

.text {
  margin-bottom: 30px;
  & > * + * {
    margin-top: 15px;
  }
  h2 {
    text-align: left;
  }
}

//body

body {
  font-family: $font;
  color: $black;
  font-style: normal;
  font-weight: normal;
  background: $white;
  font-size: 16px;
  line-height: 1.4;
  @media (max-width: $media-2) {

      font-size: 14px;

  }
}

.wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
}
main {
  padding-bottom: 35px;
  flex: auto;
  display: block;
}
footer {
  margin: auto auto 0 auto;
  width: 100%;
}

.container,
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 2;
}

.container {
  max-width: 1370px;
}
.container-fluid {
  max-width: 1920px;
}


//btn row

.btn-row {
  text-align: center;
  .btn, .btn-gray {
    margin: 5px;
  }
}


//row

.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -20px;
  .column-2 {
    width: 50%;
    padding: 10px 20px;
  }
  .column-3 {
    max-width: 320px;
    width: 33.3%;
    padding: 20px;
  }
}

//fancybox

.fancybox-navigation {
  position: static;
}

//swiper

.swiper-button {
  outline: none;
  background: url(../img/icons/next.svg) no-repeat center;
  width: 22px;
  height: 36px;
  cursor: pointer;
  opacity: .6;
  border-radius: 0;
  background-size: 16px;
  &:hover {
    opacity: 1;
  }
  &.swiper-button-prev {
    left: 0;
    transform: scaleX(-1);
  }
  &.swiper-button-next {
    right: 0;
  }
  &:before, &:after {
    display: none;
  }
}

.swiper-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  .swiper-pagination-bullet {
    opacity: 1;
    outline: none;
    &.swiper-pagination-bullet-active {

    }
  }
}

//block

.block {
  padding: 45px 0;
  @media (max-width: $media-3) {
    padding: 30px 0;
  }
}

//content

.content {
  padding: 10px 0 35px 0;
  &-item {
    width: calc(100% - 310px - 50px);
    float: right;
    @media (max-width: $media-1) {
      width: calc(100% - 280px - 30px);
    }
    @media (max-width: $media-2) {
      width: 100%;
    }
    h2 {
      text-align: left;
    }
  }
  &:after {
    content: '';
    clear: both;
    display: block;
  }
}

//list-page

.list-page {
  a {
    display: block;
    margin: 5px 0;
  }
}

//breadcrumbs

.breadcrumbs {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $light-2;
  span {
    display: inline-block;
    vertical-align: top;
    font-size: .95em;
    padding-right: 15px;
    margin-right: 5px;
    @media (max-width: $media-3) {
      font-size: .8em;
    }
    &:after {
      content: '-';
      position: absolute;
      top: 0;
      right: 0;
    }
    &:last-child {
      padding-right: 0;
      margin-right: 0;
      &:after {
        display: none;
      }
    }
    a {
      color: $blue;
      &:hover {
        color: $orange;
      }
    }
  }
}

//text-list

.text-list {
  ul {
    li {
      margin-bottom: 8px;
      padding-left: 10px;
      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 4px;
        height: 4px;
        background: $green;
        border-radius: 4px;
      }
    }
  }
}


//link

.link {
  color: $blue;
  &:hover {
    color: $green;
  }
}

//tabs

.tabs_content {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  &.active {
    opacity: 1;
    position: relative;
    z-index: 3;
  }
}

.tabs_caption {
  padding-bottom: 20px;
  li {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    transition: .5s;
    margin: 0 30px 10px 0;
    font-size: 22px;
    font-weight: 700;
    color: $blue;
    @media (max-width: $media-3) {
      font-size: 16px;
      margin: 0 15px 10px 0;
    }
    &:hover {
      opacity: .7;
    }
    &.active {
      color: $black;
      text-decoration: none;
    }
  }
}

