
.brand {
  margin: -12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    margin: 12px;
    width: 168px;
    height: 100px;
    border: 1px solid #EAF0F2;
    border-radius: $radius-1;;;
    background: $white;
    padding: 5px;
    @include verticalCenter;
    &:hover {
      box-shadow: 0px 4px 16px rgba(173, 201, 215, 0.25);
      border-color: transparent;
    }
    img {
      max-height: 100%;
    }
  }
  @media (max-width: $media-1) {
   //margin: -5px;
    a {
      //margin: 5px;
      width: 100px;
      height: 80px;
    }
  }
  @media (max-width: $media-2) {
    margin: -5px;
    a {
      margin: 5px;
    }
  }
  @media (max-width: $media-3) {
    margin: -5px;
    a {
      margin: 3px;
      width: 90px;
      height: 60px;
    }
  }
}
