@import url(../../../node_modules/swiper/css/swiper.min.css);
@import url(../../../node_modules/normalize.css/normalize.css);
@import url(../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css);
@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-LightItalic.eot");
  src: url("../fonts/Lato-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-LightItalic.woff2") format("woff2"), url("../fonts/Lato-LightItalic.woff") format("woff"), url("../fonts/Lato-LightItalic.ttf") format("truetype"), url("../fonts/Lato-LightItalic.svg#Lato-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Medium.eot");
  src: url("../fonts/Lato-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Medium.woff2") format("woff2"), url("../fonts/Lato-Medium.woff") format("woff"), url("../fonts/Lato-Medium.ttf") format("truetype"), url("../fonts/Lato-Medium.svg#Lato-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-ThinItalic.eot");
  src: url("../fonts/Lato-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-ThinItalic.woff2") format("woff2"), url("../fonts/Lato-ThinItalic.woff") format("woff"), url("../fonts/Lato-ThinItalic.ttf") format("truetype"), url("../fonts/Lato-ThinItalic.svg#Lato-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Semibold.eot");
  src: url("../fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Semibold.woff2") format("woff2"), url("../fonts/Lato-Semibold.woff") format("woff"), url("../fonts/Lato-Semibold.ttf") format("truetype"), url("../fonts/Lato-Semibold.svg#Lato-Semibold") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Hairline.eot");
  src: url("../fonts/Lato-Hairline.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Hairline.woff2") format("woff2"), url("../fonts/Lato-Hairline.woff") format("woff"), url("../fonts/Lato-Hairline.ttf") format("truetype"), url("../fonts/Lato-Hairline.svg#Lato-Hairline") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Black.eot");
  src: url("../fonts/Lato-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Black.woff2") format("woff2"), url("../fonts/Lato-Black.woff") format("woff"), url("../fonts/Lato-Black.ttf") format("truetype"), url("../fonts/Lato-Black.svg#Lato-Black") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Light.eot");
  src: url("../fonts/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Light.woff2") format("woff2"), url("../fonts/Lato-Light.woff") format("woff"), url("../fonts/Lato-Light.ttf") format("truetype"), url("../fonts/Lato-Light.svg#Lato-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-BoldItalic.eot");
  src: url("../fonts/Lato-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-BoldItalic.woff2") format("woff2"), url("../fonts/Lato-BoldItalic.woff") format("woff"), url("../fonts/Lato-BoldItalic.ttf") format("truetype"), url("../fonts/Lato-BoldItalic.svg#Lato-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Thin.eot");
  src: url("../fonts/Lato-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Thin.woff2") format("woff2"), url("../fonts/Lato-Thin.woff") format("woff"), url("../fonts/Lato-Thin.ttf") format("truetype"), url("../fonts/Lato-Thin.svg#Lato-Thin") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Heavy.eot");
  src: url("../fonts/Lato-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Heavy.woff2") format("woff2"), url("../fonts/Lato-Heavy.woff") format("woff"), url("../fonts/Lato-Heavy.ttf") format("truetype"), url("../fonts/Lato-Heavy.svg#Lato-Heavy") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-BlackItalic.eot");
  src: url("../fonts/Lato-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-BlackItalic.woff2") format("woff2"), url("../fonts/Lato-BlackItalic.woff") format("woff"), url("../fonts/Lato-BlackItalic.ttf") format("truetype"), url("../fonts/Lato-BlackItalic.svg#Lato-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-MediumItalic.eot");
  src: url("../fonts/Lato-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-MediumItalic.woff2") format("woff2"), url("../fonts/Lato-MediumItalic.woff") format("woff"), url("../fonts/Lato-MediumItalic.ttf") format("truetype"), url("../fonts/Lato-MediumItalic.svg#Lato-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-SemiboldItalic.eot");
  src: url("../fonts/Lato-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-SemiboldItalic.woff2") format("woff2"), url("../fonts/Lato-SemiboldItalic.woff") format("woff"), url("../fonts/Lato-SemiboldItalic.ttf") format("truetype"), url("../fonts/Lato-SemiboldItalic.svg#Lato-SemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Italic.eot");
  src: url("../fonts/Lato-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Italic.woff2") format("woff2"), url("../fonts/Lato-Italic.woff") format("woff"), url("../fonts/Lato-Italic.ttf") format("truetype"), url("../fonts/Lato-Italic.svg#Lato-Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Regular.eot");
  src: url("../fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato-Regular.woff") format("woff"), url("../fonts/Lato-Regular.ttf") format("truetype"), url("../fonts/Lato-Regular.svg#Lato-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-HeavyItalic.eot");
  src: url("../fonts/Lato-HeavyItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-HeavyItalic.woff2") format("woff2"), url("../fonts/Lato-HeavyItalic.woff") format("woff"), url("../fonts/Lato-HeavyItalic.ttf") format("truetype"), url("../fonts/Lato-HeavyItalic.svg#Lato-HeavyItalic") format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Bold.eot");
  src: url("../fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato-Bold.woff") format("woff"), url("../fonts/Lato-Bold.ttf") format("truetype"), url("../fonts/Lato-Bold.svg#Lato-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-HairlineItalic.eot");
  src: url("../fonts/Lato-HairlineItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-HairlineItalic.woff2") format("woff2"), url("../fonts/Lato-HairlineItalic.woff") format("woff"), url("../fonts/Lato-HairlineItalic.ttf") format("truetype"), url("../fonts/Lato-HairlineItalic.svg#Lato-HairlineItalic") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Circe Rounded';
  src: url("../fonts/CirceRounded-Regular.eot");
  src: url("../fonts/CirceRounded-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/CirceRounded-Regular.woff2") format("woff2"), url("../fonts/CirceRounded-Regular.woff") format("woff"), url("../fonts/CirceRounded-Regular.ttf") format("truetype"), url("../fonts/CirceRounded-Regular.svg#CirceRounded-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

* {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

a, button, span {
  display: inline-block; }

input, textarea {
  display: block;
  width: 100%; }

table {
  width: 100%;
  border-collapse: collapse; }

a {
  background-color: transparent;
  text-decoration: none; }

img {
  border: none;
  max-width: 100%;
  height: auto; }

a, button {
  outline: 0;
  cursor: pointer; }

li {
  list-style: none; }

button {
  background: 0 0;
  border: none; }

a, button, input, textarea {
  color: #353B4F;
  font-weight: inherit;
  -webkit-appearance: none;
  transition: all .5s ease; }

:after, :before {
  box-sizing: border-box; }

textarea {
  resize: none;
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0; }

input {
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0; }

input[type=number] {
  -moz-appearance: textfield; }

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
  -webkit-appearance: none; }

input[type=submit] {
  cursor: pointer;
  -webkit-appearance: none; }

input[type=search]::-webkit-search-cancel-button {
  background: 0 0;
  display: none; }

.text-align-left {
  text-align: left; }

.text-align-center {
  text-align: center; }

.text-align-right {
  text-align: right; }

.full-img img {
  width: 100%; }

figure {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 5px;
  font-size: .75em;
  opacity: .8; }

[hidden] {
  display: none !important; }

.img-full {
  width: 100%; }

.clearfix:after {
  content: '';
  clear: both;
  display: block; }

h1, .h1 {
  font-size: 40px;
  font-family: "Circe Rounded";
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #0099E4; }
  @media (max-width: 735px) {
    h1, .h1 {
      font-size: 28px; } }

h2, .h2 {
  font-size: 32px;
  font-family: "Circe Rounded";
  text-align: center;
  margin-bottom: 30px; }
  @media (max-width: 735px) {
    h2, .h2 {
      font-size: 24px;
      margin-bottom: 20px; } }

h3, .h3 {
  font-size: 24px;
  font-family: "Circe Rounded";
  text-align: center;
  margin-bottom: 20px; }
  @media (max-width: 735px) {
    h3, .h3 {
      font-size: 20px; } }

h4, .h4 {
  font-size: 22px;
  font-family: "Circe Rounded";
  margin-bottom: 15px; }
  @media (max-width: 1369px) {
    h4, .h4 {
      font-size: 20px; } }
  @media (max-width: 735px) {
    h4, .h4 {
      font-size: 18px;
      margin-bottom: 10px; } }

h5, .h5 {
  font-size: 1.1em;
  margin-bottom: 15px;
  font-family: "Circe Rounded"; }

h6, .h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px; }

.text {
  margin-bottom: 30px; }
  .text > * + * {
    margin-top: 15px; }
  .text h2 {
    text-align: left; }

body {
  font-family: "Lato";
  color: #353B4F;
  font-style: normal;
  font-weight: normal;
  background: #fff;
  font-size: 16px;
  line-height: 1.4; }
  @media (max-width: 1023px) {
    body {
      font-size: 14px; } }

.wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

header {
  width: 100%; }

main {
  padding-bottom: 35px;
  flex: auto;
  display: block; }

footer {
  margin: auto auto 0 auto;
  width: 100%; }

.container,
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 2; }

.container {
  max-width: 1370px; }

.container-fluid {
  max-width: 1920px; }

.btn-row {
  text-align: center; }
  .btn-row .btn, .btn-row .btn-gray {
    margin: 5px; }

.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -20px; }
  .column .column-2 {
    width: 50%;
    padding: 10px 20px; }
  .column .column-3 {
    max-width: 320px;
    width: 33.3%;
    padding: 20px; }

.fancybox-navigation {
  position: static; }

.swiper-button {
  outline: none;
  background: url(../img/icons/next.svg) no-repeat center;
  width: 22px;
  height: 36px;
  cursor: pointer;
  opacity: .6;
  border-radius: 0;
  background-size: 16px; }
  .swiper-button:hover {
    opacity: 1; }
  .swiper-button.swiper-button-prev {
    left: 0;
    transform: scaleX(-1); }
  .swiper-button.swiper-button-next {
    right: 0; }
  .swiper-button:before, .swiper-button:after {
    display: none; }

.swiper-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px; }
  .swiper-pagination .swiper-pagination-bullet {
    opacity: 1;
    outline: none; }

.block {
  padding: 45px 0; }
  @media (max-width: 735px) {
    .block {
      padding: 30px 0; } }

.content {
  padding: 10px 0 35px 0; }
  .content-item {
    width: calc(100% - 310px - 50px);
    float: right; }
    @media (max-width: 1369px) {
      .content-item {
        width: calc(100% - 280px - 30px); } }
    @media (max-width: 1023px) {
      .content-item {
        width: 100%; } }
    .content-item h2 {
      text-align: left; }
  .content:after {
    content: '';
    clear: both;
    display: block; }

.list-page a {
  display: block;
  margin: 5px 0; }

.breadcrumbs {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #DCE8EF; }
  .breadcrumbs span {
    display: inline-block;
    vertical-align: top;
    font-size: .95em;
    padding-right: 15px;
    margin-right: 5px; }
    @media (max-width: 735px) {
      .breadcrumbs span {
        font-size: .8em; } }
    .breadcrumbs span:after {
      content: '-';
      position: absolute;
      top: 0;
      right: 0; }
    .breadcrumbs span:last-child {
      padding-right: 0;
      margin-right: 0; }
      .breadcrumbs span:last-child:after {
        display: none; }
    .breadcrumbs span a {
      color: #0099E4; }
      .breadcrumbs span a:hover {
        color: #EF7F1A; }

.text-list ul li {
  margin-bottom: 8px;
  padding-left: 10px; }
  .text-list ul li:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 4px;
    height: 4px;
    background: #21A73F;
    border-radius: 4px; }

.link {
  color: #0099E4; }
  .link:hover {
    color: #21A73F; }

.tabs_content {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1; }
  .tabs_content.active {
    opacity: 1;
    position: relative;
    z-index: 3; }

.tabs_caption {
  padding-bottom: 20px; }
  .tabs_caption li {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    transition: .5s;
    margin: 0 30px 10px 0;
    font-size: 22px;
    font-weight: 700;
    color: #0099E4; }
    @media (max-width: 735px) {
      .tabs_caption li {
        font-size: 16px;
        margin: 0 15px 10px 0; } }
    .tabs_caption li:hover {
      opacity: .7; }
    .tabs_caption li.active {
      color: #353B4F;
      text-decoration: none; }

input::-webkit-input-placeholder {
  color: #A1A5B4; }

input::-moz-placeholder {
  color: #A1A5B4; }

input:-moz-placeholder {
  color: #A1A5B4; }

input:-ms-input-placeholder {
  color: #A1A5B4; }

textarea::-webkit-input-placeholder {
  color: #A1A5B4; }

textarea::-moz-placeholder {
  color: #A1A5B4; }

textarea:-moz-placeholder {
  color: #A1A5B4; }

textarea:-ms-input-placeholder {
  color: #A1A5B4; }

input:focus::-webkit-input-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus::-moz-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus:-moz-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus:-ms-input-placeholder {
  color: transparent;
  transition: all .5s ease; }

textarea:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus::-moz-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus:-moz-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

.search-field {
  background: #FFFFFF;
  box-shadow: inset 0px 4px 8px rgba(199, 202, 215, 0.6);
  border-radius: 40px;
  padding: 10px 50px 10px 23px;
  color: #353B4F;
  width: 100%;
  display: block;
  font-size: 16px; }

.search-submit {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: url(../img/icons/search.svg) no-repeat center #F4A863; }
  .search-submit:hover {
    box-shadow: 0px 6px 8px rgba(239, 127, 26, 0.6); }

@media (max-width: 1023px) {
  .search {
    display: block;
    position: fixed;
    width: auto;
    max-width: 100%;
    top: 20px;
    left: 20px;
    right: 20px;
    z-index: 9999;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    border-radius: 40px;
    -webkit-overflow-scrolling: touch;
    transform: translateY(100px);
    transition: all 0.5s ease; }
    .search-field {
      font-size: 14px;
      height: 38px;
      line-height: 38px; }
    .search.open {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.95); } }

.btn {
  font-family: "Circe Rounded";
  color: #fff;
  background-color: #EF7F1A;
  box-shadow: 0px 4px 4px rgba(239, 127, 26, 0.2);
  border-radius: 5px;
  text-align: center;
  padding: 15px 30px; }
  .btn:hover {
    background-color: #0099E4;
    box-shadow: 0px 4px 4px rgba(0, 153, 228, 0.1); }
  .btn-gray {
    border: 1px solid #B8CCD8;
    border-radius: 3px;
    font-size: 13px;
    text-align: center;
    color: #A2B4BE;
    padding: 10px 20px 8px 20px; }
    .btn-gray:hover {
      background: #F1F6F9;
      border-color: #F1F6F9;
      color: #0099E4; }
  .btn-green {
    background: #21A73F;
    box-shadow: 0px 4px 4px rgba(33, 167, 63, 0.2); }
    .btn-green:hover {
      background: #EE3E3E;
      box-shadow: 0px 4px 4px rgba(238, 62, 62, 0.2); }

.form-field {
  font-size: .95em;
  display: block; }
  .form-field input {
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid #F3F6FA;
    background-color: #F3F6FA;
    color: #353B4F;
    padding: 12px 20px; }
    .form-field input:focus {
      border-color: #0099E4; }
  .form-field input, .form-field .nice-select {
    margin: 8px 0 20px 0;
    display: block;
    width: 100%; }

.consent {
  font-size: 12px; }
  .consent label {
    display: block;
    padding-left: 28px; }
    .consent label input {
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      cursor: pointer;
      z-index: 1; }
  .consent-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #346581;
    transition: .5s; }
  .consent input:checked + .consent-icon {
    background: #0099E4;
    border-color: #0099E4; }
  .consent input:hover + .consent-icon {
    border-color: #0099E4; }

.modal, .modal-content > * {
  transform: translateY(150px);
  opacity: 0;
  -webkit-transition: opacity .5s, -webkit-transform .5s;
  transition: opacity .5s, -webkit-transform .5s;
  -o-transition: opacity .5s, -o-transform .5s;
  -moz-transition: transform .5s, opacity .5s, -moz-transform .5s;
  transition: transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s, -webkit-transform .5s, -moz-transform .5s, -o-transform .5s; }

.fancybox-slide--current .modal, .fancybox-slide--current .modal-content > * {
  opacity: 1;
  transform: translateY(0); }

.modal {
  display: none;
  max-width: 100%;
  border-radius: 5px;
  background-color: #fff;
  overflow: visible;
  padding: 30px 20px;
  width: 400px; }
  .modal h3 {
    margin-bottom: 20px; }
  .modal-movement {
    margin-bottom: 25px;
    color: #A2B4BE;
    font-size: .9em; }
  .modal .consent {
    margin: 25px auto 0 auto; }
  .modal .btn {
    min-width: 200px;
    width: auto;
    display: inline-block; }

.forgot-password {
  text-align: center;
  margin-top: 25px;
  font-size: 12px; }

.fancybox-bg {
  background: #353b4f; }

#success {
  text-align: center; }
  #success h4 {
    text-align: center; }

.header {
  background: #353B4F;
  padding: 30px 0 0 0;
  color: #fff;
  margin-bottom: 50px;
  z-index: 3; }
  @media (max-width: 1023px) {
    .header {
      padding: 15px 0;
      margin-bottom: 30px; } }
  .header-item .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 735px) {
      .header-item .container {
        padding-right: 5px;
        padding-left: 5px; } }
    .header-item .container > * {
      margin-right: 70px; }
      @media (max-width: 1369px) {
        .header-item .container > * {
          margin-right: 30px; } }
      @media (max-width: 1023px) {
        .header-item .container > * {
          margin-right: 15px; } }
      .header-item .container > *:last-child {
        margin-right: 0; }
  .header-btn {
    display: flex;
    align-items: center; }
    .header-btn > * {
      margin-right: 30px; }
      @media (max-width: 1023px) {
        .header-btn > * {
          margin-right: 15px; } }
      .header-btn > *:last-child {
        margin-right: 0; }
    .header-btn .tel {
      display: none; }
      @media (max-width: 1023px) {
        .header-btn .tel {
          display: inline-block;
          font-size: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: url(../img/icons/phone.svg) no-repeat center #fff;
          background-size: 18px;
          margin: 0 5px; } }
  .header .logo img {
    width: 340px; }
    @media (max-width: 1369px) {
      .header .logo img {
        width: 250px; } }
  @media (max-width: 1023px) {
    .header .logo {
      margin-left: 70px; }
      .header .logo img {
        width: 200px; } }
  @media (max-width: 735px) {
    .header .logo {
      margin-left: 35px;
      margin-right: 0; }
      .header .logo img {
        width: 130px; } }
  .header-column {
    width: 400px;
    max-width: 100%; }
    @media (max-width: 1369px) {
      .header-column {
        width: 350px; }
        .header-column .tel {
          font-size: 20px; } }
    @media (max-width: 1023px) {
      .header-column {
        width: auto; } }
  .header-phone {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header-phone > * {
      margin-right: 15px;
      margin-bottom: 5px; }
      .header-phone > *:last-child {
        margin-right: 0; }
    @media (max-width: 1023px) {
      .header-phone {
        display: none; } }
  .header .call-back {
    color: #0099E4; }
  .header a, .header button {
    color: #fff; }
  .header .social {
    margin-top: 20px; }

.user {
  font-size: 1.05em;
  font-weight: 700;
  padding-left: 32px;
  white-space: nowrap; }
  .user:before {
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    margin-top: -10px;
    position: absolute;
    left: 0;
    background: url(../img/icons/login.svg) no-repeat center; }
  .user:hover {
    color: #0099E4; }
  @media (max-width: 1023px) {
    .user {
      margin: 0 3px;
      font-size: 0;
      padding-left: 0;
      height: 30px;
      width: 30px;
      background-color: #fff;
      border-radius: 50%; }
      .user:before {
        width: 30px;
        height: 30px;
        margin-top: -15px;
        background-size: 17px; } }

.basket {
  font-size: 1.05em;
  font-weight: 700;
  white-space: nowrap;
  padding-left: 42px; }
  .basket span {
    color: #9CA6C7;
    font-weight: 500; }
  .basket:before {
    content: '';
    width: 28px;
    height: 28px;
    top: 50%;
    margin-top: -14px;
    position: absolute;
    left: 0;
    background: url(../img/icons/basket.svg) no-repeat center; }
  .basket:hover {
    color: #0099E4; }
  @media (max-width: 1023px) {
    .basket {
      margin: 0 3px;
      font-size: 0;
      padding-left: 0;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border-radius: 50%; }
      .basket:before {
        width: 30px;
        height: 30px;
        margin-top: -15px;
        background-size: 20px; } }

.tel {
  white-space: nowrap;
  font-weight: bold;
  font-size: 24px; }
  .tel:hover {
    opacity: .7; }

.call-back {
  color: #0099E4;
  font-weight: 500;
  font-size: 1.1em;
  white-space: nowrap;
  border-bottom: 1px dashed #0099E4; }
  .call-back:hover {
    border-color: transparent; }

.open-nav,
.close-nav,
.overlay,
.search-open {
  display: none; }

@media (max-width: 1023px) {
  .open-nav {
    display: block;
    background: url(../img/icons/catalog.svg) no-repeat center;
    width: 30px;
    height: 30px;
    background-size: 24px;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -16px; }
  .close-nav {
    display: block;
    position: absolute;
    z-index: 1;
    top: 27px;
    right: 15px;
    background: url(../img/icons/close.svg) no-repeat center;
    width: 32px;
    height: 32px;
    background-size: 14px; }
  .overlay.open {
    display: block;
    position: fixed;
    z-index: 9998;
    background: rgba(53, 59, 79, 0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .search-open {
    display: block;
    background: url(../img/icons/search-blue.svg) no-repeat center #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: 14px;
    margin: 0 3px; } }

@media (max-width: 735px) {
  .open-nav {
    background-size: 20px;
    left: 5px; } }

.wrap-nav {
  transform: translateY(50%); }
  .wrap-nav__title {
    display: none; }
  .wrap-nav__hidden {
    display: none; }
  @media (max-width: 1023px) {
    .wrap-nav {
      display: block;
      position: fixed;
      width: 360px;
      max-width: 85%;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 9999;
      overflow: auto;
      visibility: hidden;
      opacity: 0;
      padding: 35px 20px;
      margin: 0;
      -webkit-overflow-scrolling: touch;
      background: #353B4F;
      color: #fff;
      transform: translateX(-100%);
      transition: all 0.5s ease; }
      .wrap-nav.open {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.75); }
      .wrap-nav__title {
        display: block;
        color: #fff;
        font-size: 1.3em;
        margin-bottom: 35px;
        text-transform: uppercase;
        font-family: "Circe Rounded"; }
      .wrap-nav__hidden {
        display: block; }
      .wrap-nav .tel {
        margin-bottom: 10px;
        display: block; }
      .wrap-nav .call-back {
        margin: 20px 0;
        display: inline-block; } }

.menu {
  background: #21A73F;
  box-shadow: 0px 6px 8px rgba(16, 141, 44, 0.26);
  border-radius: 4px;
  display: flex;
  overflow: hidden; }
  @media (max-width: 1023px) {
    .menu {
      display: block;
      background: none;
      box-shadow: none;
      border-radius: 0;
      overflow: visible;
      border-bottom: 1px solid #A2B4BE;
      padding-bottom: 10px; } }
  .menu li {
    flex: 1; }
    @media (max-width: 1023px) {
      .menu li {
        margin: 15px 0;
        display: block; } }
    .menu li.menu-item--catalog {
      flex: 0; }
      .menu li.menu-item--catalog a {
        padding-left: 70px;
        padding-right: 30px;
        font-size: 1.1em;
        text-transform: uppercase;
        background: #0E952C; }
        .menu li.menu-item--catalog a:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 30px;
          width: 20px;
          height: 20px;
          background: url(../img/icons/catalog.svg) no-repeat center;
          margin-top: -12px; }
          @media (max-width: 1023px) {
            .menu li.menu-item--catalog a:before {
              display: none; } }
        .menu li.menu-item--catalog a:hover {
          background: #0099E4; }
        @media (max-width: 1023px) {
          .menu li.menu-item--catalog a {
            padding-left: 0;
            padding-right: 0;
            background: transparent; }
            .menu li.menu-item--catalog a:hover {
              background: transparent; } }
    .menu li.menu-item--new a {
      font-size: 1.1em;
      text-transform: uppercase; }
    .menu li a {
      white-space: nowrap;
      color: #fff;
      font-size: 1.15em;
      font-family: "Circe Rounded";
      padding: 15px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      .menu li a:hover {
        background-color: #0099E4; }
      @media (max-width: 1369px) {
        .menu li a {
          padding: 15px; } }
      @media (max-width: 1023px) {
        .menu li a {
          display: block;
          padding: 0;
          white-space: normal; } }

.footer {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0.55%, #FFFFFF 100%), #EAF2F7; }
  @media (max-width: 735px) {
    .footer {
      font-size: 14px; }
      .footer h5 {
        margin-bottom: 10px; } }
  .footer-item {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 735px) {
      .footer-item {
        flex-direction: column;
        align-items: center;
        text-align: center; } }
    .footer-item > * {
      margin-right: 30px;
      margin-bottom: 15px;
      margin-top: 15px; }
      @media (max-width: 735px) {
        .footer-item > * {
          margin-right: 0;
          margin-bottom: 10px;
          margin-top: 10px; } }
      .footer-item > *:last-child {
        margin-right: 0; }
  .footer .logo img {
    width: 160px; }
    @media (max-width: 1369px) {
      .footer .logo img {
        width: 100px; } }
    @media (max-width: 735px) {
      .footer .logo img {
        width: 80px; } }
  .footer-menu ul {
    column-gap: 30px;
    column-count: 2; }
    .footer-menu ul li {
      margin-bottom: 15px; }
      .footer-menu ul li a {
        color: #0099E4;
        font-weight: 600;
        font-size: .95em; }
        .footer-menu ul li a:hover {
          color: #353B4F; }
  @media (max-width: 1023px) {
    .footer-menu {
      display: none; } }
  .footer-contacts {
    width: 260px; }
    .footer-contacts .tel {
      display: block;
      margin-bottom: 5px;
      color: #353B4F;
      font-size: 20px; }
      .footer-contacts .tel:hover {
        color: #0099E4; }

.address {
  font-weight: 600;
  font-size: .95em;
  line-height: 1.4;
  margin-bottom: 10px; }

.email {
  display: block;
  font-weight: 500;
  color: #0099E4; }
  .email:hover {
    color: #21A73F; }

.copyright {
  text-align: center;
  border-top: 1px solid #D9E6EE;
  padding: 15px 0;
  font-weight: 500;
  font-size: .9em; }

.social {
  display: flex; }
  .social a {
    margin-right: 8px;
    width: 36px;
    text-align: center; }
    .social a:last-child {
      margin-right: 0; }
    .social a:hover {
      opacity: .7; }

.subscription {
  padding: 30px 0;
  background: url(../img/subscription-bg.jpg) no-repeat center;
  background-size: cover;
  color: #fff; }
  .subscription .container {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1023px) {
      .subscription .container {
        flex-wrap: wrap; } }
    .subscription .container > * {
      margin: 10px 0; }
  .subscription h3 {
    text-align: left;
    width: 510px;
    margin-right: 50px; }
    @media (max-width: 1023px) {
      .subscription h3 {
        text-align: center;
        width: 100%;
        margin-right: 0; } }
  .subscription-form {
    width: 400px;
    max-width: 100%; }
    .subscription-form__field {
      width: 100%;
      display: block;
      background: #FFFFFF;
      box-shadow: inset 0px 4px 8px rgba(199, 202, 215, 0.6);
      border-radius: 40px;
      padding: 10px 150px 10px 23px;
      font-size: 14px; }
      @media (max-width: 735px) {
        .subscription-form__field {
          padding-right: 23px;
          text-align: center; } }
    .subscription-form__submit {
      top: 3px;
      right: 3px;
      bottom: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #EF7F1A;
      color: #fff;
      font-size: 14px;
      border-radius: 40px;
      width: 130px;
      text-align: center;
      position: absolute;
      z-index: 2; }
      .subscription-form__submit:hover {
        box-shadow: 0px 6px 8px rgba(239, 127, 26, 0.6); }
      @media (max-width: 735px) {
        .subscription-form__submit {
          position: relative;
          display: block;
          margin: 10px auto;
          padding: 15px 10px; } }

.sidebar {
  width: 310px;
  margin-bottom: 20px;
  background: #F1F6F9;
  border-radius: 5px;
  float: left; }
  @media (max-width: 1369px) {
    .sidebar {
      width: 280px; } }
  @media (max-width: 1023px) {
    .sidebar {
      width: 100%; } }
  .sidebar-open {
    display: none;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    padding: 12px;
    font-family: "Circe Rounded"; }
    @media (max-width: 1023px) {
      .sidebar-open {
        display: block; } }

.category-list {
  padding: 15px 10px 15px 30px; }
  @media (max-width: 1023px) {
    .category-list {
      display: none; } }
  .category-list ul li {
    margin: 10px 0; }
    .category-list ul li a {
      color: #346581;
      padding-left: 15px;
      font-size: .9em;
      font-weight: 500; }
      .category-list ul li a:before {
        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        border-radius: 4px;
        width: 4px;
        height: 4px;
        background: #21A73F; }
      .category-list ul li a:hover {
        color: #21A73F; }

.brand {
  margin: -12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .brand a {
    margin: 12px;
    width: 168px;
    height: 100px;
    border: 1px solid #EAF0F2;
    border-radius: 5px;
    background: #fff;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .brand a:hover {
      box-shadow: 0px 4px 16px rgba(173, 201, 215, 0.25);
      border-color: transparent; }
    .brand a img {
      max-height: 100%; }
  @media (max-width: 1369px) {
    .brand a {
      width: 100px;
      height: 80px; } }
  @media (max-width: 1023px) {
    .brand {
      margin: -5px; }
      .brand a {
        margin: 5px; } }
  @media (max-width: 735px) {
    .brand {
      margin: -5px; }
      .brand a {
        margin: 3px;
        width: 90px;
        height: 60px; } }

.advantages {
  display: flex;
  flex-wrap: wrap; }
  .advantages-item {
    text-align: center;
    width: 25%;
    padding: 0 15px; }
    .advantages-item__icon {
      width: 85px;
      height: 85px;
      padding: 10px;
      margin: 0 auto 25px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      .advantages-item__icon img {
        max-height: 100%; }
      .advantages-item__icon:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: .5s;
        transform: rotate(45deg);
        border-radius: 8px; }
    .advantages-item:nth-child(odd) .advantages-item__icon:before {
      background-color: #21A73F; }
    .advantages-item:nth-child(even) .advantages-item__icon:before {
      background-color: #EF7F1A; }
    .advantages-item:hover .advantages-item__icon:before {
      transform: rotate(135deg); }
    .advantages-item h4 {
      margin-bottom: 5px; }
    @media (max-width: 1023px) {
      .advantages-item {
        width: 50%;
        margin-bottom: 35px; } }
    @media (max-width: 735px) {
      .advantages-item {
        width: 50%;
        padding: 0 2px;
        margin-bottom: 20px; }
        .advantages-item__icon {
          width: 36px;
          height: 36px;
          padding: 5px;
          margin-bottom: 10px; }
        .advantages-item h4 {
          font-size: 1em; }
        .advantages-item p {
          font-size: .8em; } }

.jumbotron {
  padding: 70px 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px; }
  .jumbotron-content {
    width: 500px;
    max-width: 100%;
    z-index: 1; }
    .jumbotron-content p {
      font-size: 1.2em;
      margin-bottom: 30px;
      font-weight: 600; }
    .jumbotron-content .btn {
      font-size: 1.25em;
      min-width: 245px; }
  @media (max-width: 1023px) {
    .jumbotron {
      padding: 35px 20px;
      color: #fff; }
      .jumbotron:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #353B4F;
        opacity: .5;
        border-radius: 5px; }
      .jumbotron h1 {
        color: #fff; } }
  @media (max-width: 735px) {
    .jumbotron {
      text-align: center; }
      .jumbotron-content {
        width: 100%; }
      .jumbotron h1 {
        text-align: center; }
      .jumbotron p {
        font-size: 1em; }
      .jumbotron .btn {
        min-width: auto;
        font-size: 1.1em; } }

.slider-catalog .swiper-slide {
  text-align: center;
  width: 20%;
  padding: 0 5px; }
  @media (max-width: 1369px) {
    .slider-catalog .swiper-slide {
      width: 25%; } }
  @media (max-width: 1023px) {
    .slider-catalog .swiper-slide {
      width: 33.3%; } }
  @media (max-width: 735px) {
    .slider-catalog .swiper-slide {
      width: 50%; } }
  @media (max-width: 580px) {
    .slider-catalog .swiper-slide {
      width: 100%; } }

.slider-catalog .product-preview {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center; }
  .slider-catalog .product-preview p {
    font-size: .9em; }
  .slider-catalog .product-preview__img {
    width: 210px;
    max-width: 85%;
    margin-bottom: 15px; }
  .slider-catalog .product-preview__price {
    margin-bottom: 5px; }
    .slider-catalog .product-preview__price .price {
      font-size: 21px; }
  .slider-catalog .product-preview .btn {
    margin-top: 10px; }
  .slider-catalog .product-preview:hover .product-preview__title {
    color: #353B4F; }

.slider-catalog .swiper-button {
  top: 147px; }

.product-preview__img {
  height: 210px;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .product-preview__img img {
    max-height: 100%;
    border-radius: 5px; }

.product-preview__title {
  font-weight: 700;
  color: #0099E4;
  transition: .5s; }
  .product-preview__title span {
    display: block;
    font-size: .9em;
    color: #353B4F; }

.product-preview .vendor-code {
  margin-top: 5px; }

.product-preview__calc {
  width: 410px;
  max-width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 1369px) {
    .product-preview__calc {
      width: auto;
      flex-direction: column;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: flex-start; } }
  .product-preview__calc > * {
    margin-right: 15px;
    margin-bottom: 10px; }
    .product-preview__calc > *:last-child {
      margin-right: 0; }

.vendor-code {
  display: block;
  font-size: .9em;
  color: #A2B4BE;
  margin: 4px 0 8px 0;
  font-weight: 500; }

.product-mark {
  position: absolute;
  color: #fff;
  z-index: 2;
  top: -35px;
  left: 0;
  padding-top: 3px;
  font-size: 13px;
  font-weight: 700;
  pointer-events: none;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .product-mark:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../img/icons/mark.png) no-repeat center;
    top: 0;
    left: 0;
    z-index: -1; }

.products {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start; }
  .products .product-preview {
    width: 100%;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .products .product-preview > * {
      margin-right: 30px; }
      .products .product-preview > *:last-child {
        margin-right: 0; }
    .products .product-preview__img {
      width: 210px;
      max-width: 100%; }
    .products .product-preview__info {
      flex: 1; }
    .products .product-preview__title {
      color: #0099E4; }
      .products .product-preview__title:hover {
        color: #EF7F1A; }
    .products .product-preview .vendor-code {
      color: #A2B4BE;
      margin-bottom: 15px; }
    .products .product-preview p {
      font-size: .9em; }
    .products .product-preview__price .price {
      margin-right: 10px; }
    .products .product-preview__availability {
      text-align: right; }
    .products .product-preview .btn {
      white-space: nowrap;
      font-size: 1.15em; }
    @media (max-width: 735px) {
      .products .product-preview {
        width: 50%;
        padding: 0 10px;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column; }
        .products .product-preview * {
          align-content: center;
          justify-content: center;
          align-items: center;
          text-align: center; }
        .products .product-preview > * {
          margin-right: 0;
          margin-bottom: 10px; }
        .products .product-preview__info {
          width: 100%; }
          .products .product-preview__info p {
            font-size: 12px; }
        .products .product-preview .price {
          font-size: 20px; }
        .products .product-preview__title {
          font-size: 1em; }
        .products .product-preview .vendor-code {
          font-size: .8em;
          margin: 3px 0 5px 0; }
        .products .product-preview__availability .btn {
          font-size: 1em; }
        .products .product-preview__availability .availability {
          text-align: center;
          flex-direction: column;
          margin-bottom: 10px; }
          .products .product-preview__availability .availability p {
            margin-right: 0;
            margin-bottom: 5px; }
          .products .product-preview__availability .availability-stat {
            justify-content: center; }
        .products .product-preview__img {
          order: 0;
          width: 140px;
          height: 120px; } }
    @media (max-width: 413px) {
      .products .product-preview {
        width: 100%; } }

.price {
  font-weight: bold;
  font-size: 26px;
  white-space: nowrap; }
  .price-old {
    display: inline-block;
    vertical-align: middle;
    margin: 3px 0;
    text-decoration: line-through;
    color: #A1A5B4;
    font-size: 14px;
    white-space: nowrap; }

.number {
  width: 123px;
  height: 34px; }
  .number input {
    width: 100%;
    display: block;
    text-align: center;
    height: 34px;
    line-height: 34px;
    font-weight: bold;
    font-size: .95em;
    color: #353B4F;
    padding: 0 34px;
    background: #F1F6F9;
    border-radius: 30px; }
  .number span {
    position: absolute;
    top: 2px;
    bottom: 2px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background-color: #DCE8EF;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    cursor: pointer; }
    .number span.minus {
      left: 2px;
      background-image: url(../img/icons/minus.svg); }
      .number span.minus:hover {
        background-image: url(../img/icons/minus-white.svg); }
    .number span.plus {
      right: 2px;
      background-image: url(../img/icons/plus.svg); }
      .number span.plus:hover {
        background-image: url(../img/icons/plus-white.svg); }

.availability {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }
  .availability p {
    font-size: .95em;
    margin-right: 10px; }
  .availability-stat {
    display: flex; }
    .availability-stat span {
      background: #dee7ee;
      width: 3px;
      height: 18px;
      margin-left: 2px; }
    .availability-stat--green .active {
      background-color: #21A73F; }
    .availability-stat--orange .active {
      background-color: #EF7F1A; }
    .availability-stat--red .active {
      background-color: #EE3E3E; }

.pagination {
  padding: 10px 0 20px 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.1em; }
  @media (max-width: 413px) {
    .pagination {
      font-size: .85em; } }
  .pagination-count {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pagination-count > * {
      margin: 0 10px; }
  .pagination a {
    color: #0099E4; }
    .pagination a:hover {
      color: #21A73F; }
  .pagination .swiper-button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #DCE8EF;
    background-size: 14px; }
    .pagination .swiper-button.prev {
      transform: scaleX(-1); }
    @media (max-width: 413px) {
      .pagination .swiper-button {
        display: none; } }

.catalog {
  margin: 0 -10px 30px -10px;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 735px) {
    .catalog {
      margin: 0 -3px 30px -3px; } }
  .catalog-item {
    width: calc(25% - 20px);
    text-align: center;
    padding: 15px 10px;
    margin: 0 10px 15px 10px;
    border-radius: 5px;
    overflow: hidden;
    font-weight: 700;
    color: #0099E4; }
    .catalog-item__img {
      width: 100%;
      height: 110px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      .catalog-item__img img {
        max-height: 100%; }
    .catalog-item:hover {
      box-shadow: 0px 4px 16px rgba(173, 201, 215, 0.25); }
    @media (max-width: 1369px) {
      .catalog-item {
        width: calc(33.3% - 20px); } }
    @media (max-width: 735px) {
      .catalog-item {
        width: calc(50% - 5px);
        margin: 0 2px 10px 2px;
        font-size: .9em;
        padding: 10px 5px; }
        .catalog-item__img {
          height: 80px;
          margin-bottom: 10px; } }

.product {
  display: flex; }
  .product-slider {
    width: 43%;
    margin-bottom: 30px; }
    .product-slider .swiper-slide {
      overflow: hidden; }
      .product-slider .swiper-slide:before {
        content: '';
        padding-top: 100%;
        display: block; }
      .product-slider .swiper-slide img {
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .product-slider .gallery-top {
      border: 1px solid #DCE8EF;
      border-radius: 5px;
      margin-bottom: 10px; }
    .product-slider .gallery-thumbs .swiper-slide {
      cursor: pointer;
      border: 1px solid #DCE8EF;
      border-radius: 5px; }
  .product-text {
    width: 57%;
    padding-left: 50px; }
    .product-text h2 {
      font-size: 30px;
      margin-bottom: 10px; }
    .product-text .vendor-code {
      color: #A2B4BE;
      margin: 0 0 10px 0; }
    .product-text .availability {
      margin-top: 20px;
      margin-bottom: 3px;
      padding-bottom: 0; }
    .product-text__list {
      padding-bottom: 15px;
      font-size: .9em; }
      .product-text__list p {
        margin: 5px 0; }
    .product-text__info {
      padding: 25px 30px;
      background: #F7FAFC;
      border-radius: 5px;
      margin-bottom: 15px; }
      @media (max-width: 735px) {
        .product-text__info {
          padding: 20px 15px; } }
  .product-hidden {
    display: none;
    margin-bottom: 30px; }
    .product-hidden h2 {
      margin-bottom: 5px; }
    @media (max-width: 1369px) {
      .product-hidden {
        display: block; } }
  @media (max-width: 1369px) {
    .product {
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center; }
      .product-text {
        width: 100%;
        padding-left: 0; }
        .product-text h2, .product-text .vendor-code {
          display: none; }
      .product-slider {
        min-width: 280px; } }

.certificate {
  margin-right: -15px; }
  .certificate a {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 200px;
    max-width: calc(50% - 30px); }
    .certificate a img {
      width: 100%; }

.basket-head {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 735px) {
    .basket-head {
      flex-direction: column;
      justify-content: center; } }
  .basket-head h2 {
    margin-right: 30px;
    margin-bottom: 10px; }
  .basket-head .link {
    font-weight: 700;
    font-size: 1.1em; }
  .basket-head__col {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 735px) {
      .basket-head__col {
        flex-direction: column;
        text-align: center;
        justify-content: center; }
        .basket-head__col h2 {
          margin-right: 0; } }

.content-basket {
  float: left;
  width: calc(100% - 290px - 30px);
  margin-bottom: 35px; }
  @media (max-width: 1369px) {
    .content-basket {
      width: 100%; } }

.checkout {
  margin-top: 80px;
  margin-bottom: 35px;
  float: right;
  width: 290px;
  background: #F1F6F9;
  border-radius: 5px;
  padding: 25px 30px 20px 30px; }
  @media (max-width: 1369px) {
    .checkout {
      width: 100%;
      margin-top: 0;
      text-align: center; } }
  .checkout .btn {
    width: 100%; }
    .checkout .btn + .btn {
      margin-top: 10px; }
  .checkout-form {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #DFE8EE; }
    .checkout-form p {
      font-size: .9em; }
  .checkout textarea {
    background: #FFFFFF;
    box-shadow: inset 0px 2px 3px rgba(230, 237, 243, 0.6);
    border-radius: 3px;
    height: 110px;
    margin: 10px 0 15px 0;
    width: 100%;
    display: block;
    padding: 15px;
    font-size: .95em; }

.note {
  font-size: .8em;
  text-align: center;
  margin-top: 15px; }

.discount-percentage {
  color: #21A73F;
  font-weight: 700;
  margin: 8px 0;
  font-size: 1.1em; }

.amount-total {
  font-size: 20px;
  font-weight: 700; }

.basket-table table {
  width: 100%; }

.basket-table thead {
  border-bottom: 2px solid #21A73F; }
  .basket-table thead th {
    text-align: left;
    font-weight: normal;
    padding: 10px 15px 10px 0; }
    .basket-table thead th:last-child {
      padding-right: 0; }

.basket-table tbody tr {
  border-bottom: 1px solid #DCE8EF; }
  .basket-table tbody tr:last-child {
    border-bottom: transparent; }

.basket-table tbody td {
  padding: 30px 15px 30px 0; }
  .basket-table tbody td:last-child {
    padding-right: 0; }

.basket-table .product-preview {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .basket-table .product-preview__img {
    width: 130px;
    height: 130px;
    margin-right: 20px; }
    @media (max-width: 1023px) {
      .basket-table .product-preview__img {
        width: 100px;
        height: 100px; } }
  .basket-table .product-preview__title {
    flex: 1; }

.basket-table .price {
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 18px; }

.basket-table .price-old {
  margin-top: 0;
  padding-top: 0; }

.delete {
  width: 20px;
  height: 20px;
  background-image: url(../img/icons/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px; }
  .delete:hover {
    background-image: url(../img/icons/close-red.svg); }

@media (max-width: 735px) {
  .basket-table table {
    display: block; }
  .basket-table thead {
    display: none; }
  .basket-table tbody {
    display: block; }
  .basket-table tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0; }
    .basket-table tr td {
      padding-top: 5px;
      padding-bottom: 5px;
      position: static; }
      .basket-table tr td:nth-child(1) {
        width: 100%; }
      .basket-table tr td .product-preview {
        justify-content: center; }
        .basket-table tr td .product-preview__img {
          width: 80px;
          height: 80px;
          margin-right: 15px; }
        .basket-table tr td .product-preview__title {
          padding-right: 20px; }
  .basket-table .delete {
    position: absolute;
    top: 18px;
    right: 0;
    z-index: 2; } }
